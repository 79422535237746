@font-face {
    font-family: 'Sweet Sans Pro Regular';
    src: url("./fonts/SweetSansProRegular.woff");
}
@font-face {
    font-family: 'Sweet Sans Pro Medium';
    src: url("./fonts/SweetSansProMedium.woff");
}
@font-face {
    font-family: 'Sweet Sans Pro Light';
    src: url("./fonts/SweetSansProLight.woff");
}
@font-face {
    font-family: 'General Sans Semibold';
    src: url('./fonts/GeneralSans-Semibold.otf');
}
@font-face {
    font-family: 'General Sans Medium';
    src: url('./fonts/GeneralSans-Medium.otf');
}
@font-face {
    font-family: 'General Sans bold';
    src: url('./fonts/GeneralSans-Bold.otf');
}
@font-face {
    font-family: 'General Sans';
    src: url('./fonts/GeneralSans-Regular.otf');
}

body {
    font-family: 'Sweet Sans Pro Regular';
}

.registration-page input.MuiOutlinedInput-input::-moz-placeholder {
    color: #8D8B8B;
}
.registration-page input.MuiOutlinedInput-input::placeholder {
color: #8D8B8B;
}
.registration-page input.MuiOutlinedInput-input {
color: #000;
opacity: 1 !important;
}
.registration-page h4.MuiTypography-h4 {
    font-family: 'General Sans Semibold';
}
.registration-page h5.MuiTypography-root, .registration-page input.MuiOutlinedInput-input, .registration-page p.MuiTypography-root {
    font-family: 'General Sans Medium';
}